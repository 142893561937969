import React, { Fragment } from "react"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"
import MediaBox from "../../../Elements/MediaBox"

import registrationSteps from "../utils/registrationSteps.json"
import useAboutImages from "../hooks/useAboutImages"

const AboutRegistrationSteps = ({ type, module }) => {
  const data = useAboutImages()
  switch (type) {
    case "patient":
      const patientRegistration = registrationSteps[type]
      return (
        <Hero color="light" size="small">
          <Container isCentered desktop={10} fullhd={8}>
            <h3 className="my-3 has-text-centered has-text-primary">
              {patientRegistration?.header}
            </h3>
            <div className="columns is-centered mb-3">
              {patientRegistration?.steps?.map((step, index) => (
                <Fragment>
                  <MediaBox
                    title={
                      <h3 className="has-text-primary is-size-4">
                        {step?.title}
                      </h3>
                    }
                    image={{
                      ...data[step?.imageName]?.childImageSharp?.fixed,
                      alt: step.imageName,
                      width: "100px",
                    }}
                    className="is-align-items-center"
                  >
                    <p className="has-text-centered">{step?.description}</p>
                  </MediaBox>
                </Fragment>
              ))}
            </div>
          </Container>
        </Hero>
      )

    case "doctor":
      const doctorRegistration = registrationSteps[type]
      return (
        <Hero color="light" size="small">
          <Container isCentered desktop={10} fullhd={8}>
            <h3 className="my-3 has-text-centered has-text-primary">
              {doctorRegistration?.header}
            </h3>
            <div className="columns is-centered mb-3">
              {doctorRegistration?.steps?.map((step, index) => (
                <Fragment>
                  <MediaBox
                    title={
                      <h3 className="has-text-primary is-size-4">
                        {step?.title}
                      </h3>
                    }
                    image={{
                      ...data[step?.imageName]?.childImageSharp?.fixed,
                      alt: step.imageName,
                      width: "100px",
                    }}
                    className="is-align-items-center"
                  >
                    <p className="has-text-centered">{step?.description}</p>
                  </MediaBox>
                </Fragment>
              ))}
            </div>
          </Container>
        </Hero>
      )

    default:
      return null
  }
}

export default AboutRegistrationSteps
