import React from "react"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"

const AboutMechanics = ({ pageContent }) => {
  const PAGE_DATA = pageContent?.content?.childMarkdownRemark?.html

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 has-text-centered has-text-primary">
          {pageContent?.title}
        </h3>
        <div className="columns is-centered mb-3">
          <div
            className="content column is-align-items-center"
            dangerouslySetInnerHTML={{ __html: PAGE_DATA }}
          />
        </div>
      </Container>
    </Hero>
  )
}

export default AboutMechanics
