import React from "react"
import { navigate } from "gatsby"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"
import Button from "../../../Elements/Button"
import useAboutImages from "../hooks/useAboutImages"

const AboutHeader = ({ type, module }) => {
  const data = useAboutImages()
  const heart = data?.heart?.childImageSharp?.fluid?.src

  switch (type) {
    case "patient":
      return (
        <Hero
          size="medium"
          background={{
            image: heart,
            position: "center",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <Container mobile={12} tablet={8} padding={0}>
              <div className="mb-1">
                <h1 className="title is-size-1 has-text-primary">
                  {module?.title}
                </h1>

                <h3 className="mt-2 subtitle">{module?.enroll?.label}</h3>
              </div>
              <Button
                color="primary"
                size="medium"
                onClick={() => navigate(module?.enroll?.path)}
              >
                {module?.enroll?.buttonLabel}
              </Button>
              <div className="mb-1">
                <h3 className="mt-2 subtitle">{module?.order?.label}</h3>
              </div>
              <Button
                color="primary"
                size="medium"
                onClick={() => navigate(module?.order?.path)}
              >
                {module?.order?.buttonLabel}
              </Button>
            </Container>
          </Container>
        </Hero>
      )

    case "doctor":
      return (
        <Hero
          size="medium"
          background={{
            image: heart,
            position: "center",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <Container mobile={12} tablet={8} padding={0}>
              <div className="mb-2">
                <h1 className="title is-size-1 has-text-primary">
                  {module?.title}
                </h1>

                <h3 className="mt-2 subtitle">{module?.enroll?.label}</h3>
              </div>
              <Button
                color="primary"
                size="medium"
                onClick={() => navigate(module?.enroll?.path)}
              >
                {module?.enroll?.buttonLabel}
              </Button>
            </Container>
          </Container>
        </Hero>
      )

    default:
      return null
  }
}

export default AboutHeader
