import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../Layout"
import Hero from "../../Layout/Hero"
import Container from "../../Layout/Container"
import AboutHeader from "./components/AboutHeader"
import AboutBenefits from "./components/AboutBenefits"
import AboutRegistrationSteps from "./components/AboutRegistrationSteps"
import AboutMechanics from "./components/AboutMechanics"

import { GATSBY_BRIEFING_GUIDE_LINK } from "gatsby-env-variables"

const AboutPage = ({ pageContext }) => {
  const { module, pageContent } = pageContext

  return (
    <Layout seoTitle={module?.seoTitle} pageContext={pageContext}>
      <div>
        <AboutHeader type={module?.name} module={module} />
        <AboutBenefits type={module?.name} />
        <AboutRegistrationSteps type={module?.name} module={module} />
        <AboutMechanics pageContent={pageContent[0]} />
        {module?.name === "doctor" && (
          <Hero size="small">
            <Container isCentered desktop={10} fullhd={8}>
              <div className="has-text-centered mt-1">
                <a
                  className="button is-primary is-medium"
                  href={GATSBY_BRIEFING_GUIDE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon is-medium">
                    <FontAwesomeIcon icon={faDownload} />
                  </span>
                  <span>{"Download Guides"}</span>
                </a>
                <div className="is-size-6 mt-1">
                  Briefing Guides.pdf (1.5MB)
                </div>
              </div>
            </Container>
          </Hero>
        )}
      </div>
    </Layout>
  )
}

export default AboutPage
