import { useStaticQuery, graphql } from "gatsby";

const useAboutImages = () => {
  const data = useStaticQuery(graphql`
    {
      prescription: file(
        relativePath: { eq: "icons/me__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      doctor: file(relativePath: { eq: "icons/me__teleconsult.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      delivery: file(relativePath: { eq: "icons/me__delivery.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      searchMedicines: file(
        relativePath: { eq: "icons/me__search-medicines.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      provideDetails: file(
        relativePath: { eq: "icons/me__provide-details.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      receiveMedicines: file(relativePath: { eq: "icons/me__blue-pack.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
};

export default useAboutImages;
