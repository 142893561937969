import React, { Fragment } from "react"
import Container from "../../../Layout/Container"

import Hero from "../../../Layout/Hero"
import MediaBox from "../../../Elements/MediaBox"

import useAboutImages from "../hooks/useAboutImages"
import benefits from "../utils/programBenefits.json"

const AboutBenefits = ({ type }) => {
  const data = useAboutImages()
  switch (type) {
    case "patient":
      const patientBenefits = benefits[type]

      return (
        <Hero color="white" size="small">
          <Container isCentered desktop={10} fullhd={8}>
            <h3 className="my-3 has-text-centered has-text-primary">
              {patientBenefits?.header}
            </h3>
            <div className="columns is-centered">
              {patientBenefits?.benefits?.map((benefit, index) => (
                <Fragment>
                  <MediaBox
                    image={{
                      ...data[benefit?.imageName]?.childImageSharp?.fixed,
                      alt: benefit.imageName,
                    }}
                  >
                    <p className="has-text-centered">{benefit.description}</p>
                  </MediaBox>
                </Fragment>
              ))}
            </div>
          </Container>
        </Hero>
      )

    case "doctor":
      const doctorBenefits = benefits[type]
      return (
        <Hero color="white" size="small">
          <Container isCentered desktop={10} fullhd={8}>
            <h3 className="my-3 has-text-centered has-text-primary">
              {doctorBenefits?.header}
            </h3>
            <div className="columns is-centered">
              {doctorBenefits?.benefits?.map((benefit, index) => (
                <Fragment>
                  <MediaBox
                    image={{
                      ...data[benefit?.imageName]?.childImageSharp?.fixed,
                      alt: benefit.imageName,
                    }}
                  >
                    <p className="has-text-centered">{benefit.description}</p>
                  </MediaBox>
                </Fragment>
              ))}
            </div>
          </Container>
        </Hero>
      )

    default:
      return null
  }
}

export default AboutBenefits
